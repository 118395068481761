<template>
	<div
		class="day"
		:class="{
			spacer: spacer,
			disabled: disabled,
			selected: selected,
			occupied: occupied
		}"
	>
		{{ dayLabels[day] || day || '' }}
	</div>
</template>

<script>
export default {
	props: {
		day: {
			type: [String, Number],
			required: true
		},
		selected: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		spacer: {
			type: Boolean,
			required: false,
			default: false
		},
		occupied: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data() {
		return {
			dayLabels: {
				d1: 'Po',
				d2: 'Ut',
				d3: 'St',
				d4: 'Št',
				d5: 'Pi',
				d6: 'So',
				d0: 'Ne'
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.day
	border: 1px solid #666
	border-radius: 3px
	color: #666
	width: 40px
	display: flex
	align-items: center
	justify-content: center
	margin: 5px
	font-size: 14px

	&:after
		content: ''
		float: left
		padding-top: 100%

	&:not(.disabled)
		cursor: pointer

	&.spacer
		border: 1px solid transparent !important
		background-color: transparent !important

	&.disabled
		color: #DADADA
		border: 1px solid #DADADA
		cursor: initial
		background-color: transparent

	&.occupied
		border: 2px solid rgba(#C20A0A, 0.3) !important
		background-color: rgba(#C20A0A, 0.15) !important

	&.selected
		color: #666
		border: 2px solid #76AD63
		background-color: rgba(#76AD63, 0.25) !important

.web
	.day
		border-radius: 50%
		border-color: #E8E4DA
		position: relative
		background-color: rgba(#E8E4DA, 0.20)

		&.disabled
			color: #E8E4DA
			border: none
			cursor: initial
			background-color: transparent

		&.occupied
			color: #666

		&.selected
			color: #666
			border: 2px solid #76AD63

		&.selected::before
			content: url('./img/checkmark.svg')
			position: absolute
			top: -6px
			right: -6px

	.readonly
		.day:not(.selected):not(.disabled)
			&:hover
				background-color: transparent
				border: 2px solid #76AD63
</style>
